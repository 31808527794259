import * as Sentry from '@sentry/browser';
import { ConfigSentryS } from '../../S/Function/module/ConfigSentryS.js';
import { getSentryBrowserOption } from './private/getSentryBrowserOption.js';
import { getUserData } from './private/configureScope.js';
import { each, entries, go, map, pick } from 'fxjs/es';
import html2canvas from 'html2canvas';
import axios from 'axios';
import { UtilObjS } from '../../../../Util/Object/S/Function/module/UtilObjS.js';

export const capture = (...args) => {
  if (!isSentryEnabled()) {
    console.error(...args);
    return;
  }

  Sentry.withScope((scope) => {
    scope.setLevel('error');
    scope.setExtra('arguments', args);
    Sentry.captureException(...args);
  });
};

export const makerCapture = async (...args) => {
  if (!isSentryEnabled()) {
    console.error(...args);
    return;
  }

  const screen = window.screen;
  const time = new Date();

  Sentry.withScope((scope) => {
    scope.setLevel('error');
    scope.setTag('module_name', 'maker');
    scope.setContext('maker_set', {
      product: pick(
        ['id', 'base_product_id', 'base_product_color_id', 'base_product_size_id'],
        box.sel('maker->product_color'),
      ),

      current_canvass: go(box.sel('maker->canvass'), map(pick(['bpf_id', 'face_name', 'fcanvas_data']))),
      screen: {
        orientation_type: screen.orientation?.type,
        width: screen.width,
        height: screen.height,
      },
      time,
    });
    Sentry.captureException(...args);
  });
  const screenshot_url = await new Promise((resolve) => {
    html2canvas(document.body)
      .then(async (canvas) => {
        try {
          const new_canvas = document.createElement('canvas');
          new_canvas.width = screen.width;
          new_canvas.height = screen.height;
          const ctx = new_canvas.getContext('2d');
          const ratio = canvas.width / new_canvas.width;
          ctx.drawImage(
            canvas,
            0,
            window.scrollY,
            canvas.width,
            new_canvas.height * ratio,
            0,
            0,
            new_canvas.width,
            new_canvas.height,
          );
          const { url } = await $.uploadFileToOnlyOriginalUrl({
            url: new_canvas.toDataURL('image/jpeg'),
            image_type: 'jpeg',
          });
          resolve(url);
        } catch (e) {
          resolve('screenshot_url____error');
        }
      })
      .catch((e) => {
        resolve('html2canvas____error');
      });
  });
  await axios.post(`/${T.lang}/@api/prerequisite_maker/error_logs`, {
    json_memo: {
      screenshot_url,
      product_faces2: box.sel('maker->product_color->product_faces2->value'),
      time,
    },
    name: 'maker',
  });
};

export const makerEventCapture = async (e, name) => {
  if (!isSentryEnabled()) {
    console.error(ConfigSentryS.getSentryLogMessage('error', e));
    return;
  }

  Sentry.withScope((scope) => {
    scope.setLevel('error');
    scope.setTag('module_name', name);
    Sentry.captureException(e);
  });
};

/**
 * @param {Unknown} error
 * @param {object?} [args = {}]
 * @param {object?} [args.option = {}] Sentry 옵션, Sentry `CaptureContext` 타입 참고
 * @param {object?} [args.extra = {}] Sentry error 로깅할 때 추가할 데이터
 */
export const error = (error, { tags = {}, option = {}, extra = {} } = {}) => {
  if (!isSentryEnabled()) {
    console.error(ConfigSentryS.getSentryLogMessage('error', error));
    return;
  }

  Sentry.withScope((scope) => {
    scope.setLevel('error');
    if (extra) {
      go(
        extra,
        entries,
        each(([k, v]) => {
          scope.setExtra(k, v);
        }),
      );
    }
    if (UtilObjS.isNotEmpty(tags)) {
      scope.setTags(tags);
    }
    Sentry.captureException(error, option);
  });
};

/**
 * @param {Error|Object} warning
 * @param {object} [args = {}]
 * @param {object} [args.option = {}] Sentry 옵션, Sentry `CaptureContext` 타입 참고
 */
export const warning = (warning, { option = {} } = {}) => {
  if (!isSentryEnabled()) {
    console.warn(ConfigSentryS.getSentryLogMessage('warning', warning));
    return;
  }

  Sentry.withScope((scope) => {
    scope.setLevel('warning');
    Sentry.captureException(warning, option);
  });
};

/**
 * @param {Error|Object} info
 * @param {object} [args = {}]
 * @param {object} [args.option = {}] Sentry 옵션, Sentry `CaptureContext` 타입 참고
 */
export const info = (info, { option = {} } = {}) => {
  if (!isSentryEnabled()) {
    console.log(ConfigSentryS.getSentryLogMessage('info', info));
    return;
  }

  Sentry.withScope((scope) => {
    scope.setLevel('info');
    Sentry.captureException(info, option);
  });
};

/**
 * @param {string} message
 * @param {object} [args = {}]
 * @param {object} [args.option = {}] Sentry 옵션, Sentry `CaptureContext` 타입 참고
 */
export const message = (message, { option = {} } = {}) => {
  isSentryEnabled() && console.log(ConfigSentryS.getSentryLogMessage('info', message));

  Sentry.withScope((scope) => {
    scope.setLevel(option.level || 'info');
    Sentry.captureMessage(message, option);
  });
};

/**
 * @param {Error|Object} debug
 * @param {object} [args = {}]
 * @param {object} [args.option = {}] Sentry 옵션, Sentry `CaptureContext` 타입 참고
 */
export const debug = (debug, { option = {} } = {}) => {
  if (!isSentryEnabled()) {
    console.log(ConfigSentryS.getSentryLogMessage('debug', debug));
    return;
  }

  Sentry.withScope((scope) => {
    scope.setLevel('debug');
    Sentry.captureException(debug, option);
  });
};

export const main = () => {
  const { lang, app } = window.ENV || {};
  const { is_user } = window.box();
  const user = getUserData(is_user);
  const app_name = ConfigSentryS.getAppName(app);

  const sentry_browser_options = getSentryBrowserOption();

  Sentry.init(sentry_browser_options);
  Sentry.configureScope((scope) => {
    scope.setTag('lang', lang);
    scope.setTag('app', app_name);
    if (window.ReactNativeWebView) scope.setTag('is_app', true);
    scope.setExtra('referrer', document.referrer);
    if (user) scope.setUser(user);
  });
};

const isSentryEnabled = () => {
  const options = Sentry?.getCurrentHub()?.getClient()?.getOptions();
  if (options) return options.enabled;

  return false;
};
