import { MShopUtilF } from '../../../../modules/MShop/Util/F/Function/module/MShopUtilF';
import { PostMessageNavigate } from './navigate';
import { loading, loadingEnd } from '../client/global';

export const isApp = (): boolean => {
  return MShopUtilF.isApp();
};

export const mpsPostMessage = MShopUtilF.postMessage;

/**
 * 웹/앱 환경에 따라 적절한 방식으로 페이지 이동을 처리합니다.
 * 앱에서는 postMessage를, 웹에서는 location.href를 사용합니다.
 *
 * @param url - 이동할 URL 주소
 * @param makePostMessageNavigateFn - 앱 네이티브 화면 전환을 위한 함수. 아래 make*Navigate 함수들 중 하나를 전달
 * @param override_key - override_key를 주면 새로운 스크린을 띄우는 것이 아닌 기존 스크린을 대체하게 된다.
 *
 * @example
 * navigateTo('/home', makeHomeScreenNavigate);
 * navigateTo('/popup-store', makePopupScreenNavigate);
 * navigateTo('/my-cart/123', makeEmptyScreenNavigate);
 */
export const navigateTo = (
  url: string,
  makePostMessageNavigateFn: (uri: string, override_key?: string) => PostMessageNavigate,
  override_key?: string,
) => {
  if (isApp()) {
    loading();
    mpsPostMessage(makePostMessageNavigateFn(url, override_key));
    loadingEnd();
    return;
  }

  window.location.href = url;
};
