import { $addClass, $find, $qs, $setCss, $setText } from 'fxdom/es';
import { delay, go } from 'fxjs/es';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';

export const alert = ({ title, body, ok, optional }) => {
  const prom = $.alert(`${title ? `<div data-only-title="${!body}" class="title">${title}</div>` : ``}
      ${body ? `<div class="popup-body">${body}</div>` : ``} ${optional || ``}`);
  const el = $qs('.don_dialog_msg:last-child');
  const is_ness = UtilS.isNessApp();

  go(el, $addClass(is_ness ? 'ness-pop-up-style-1' : 'mshop-pop-up-style-1'));
  if (ok) {
    go(el, $find('.ok'), $setText(ok));
  }

  if (UtilS.isMarppleShopApp()) {
    go(el, $find('button'), delay(1), $setCss({ transition: 'background-color 200ms ease-out' }));
  }
  return prom;
};
