import { z } from 'zod';
import { MEMBERSHIP_STORE_CHANGED, MEMBERSHIP_STORE_CHANGED_ACTION_TYPE } from '../constant';

const NumericStringSchema = z.string().regex(/^\d+$/, 'must contain only numeric characters');
const uid = z.string();

export const membershipStoreChangedActionTypeEnum = z.nativeEnum(MEMBERSHIP_STORE_CHANGED_ACTION_TYPE);

export const membershipStoreChangedActionDataScheme = z.object({
  project_id: NumericStringSchema,
  payload: z.discriminatedUnion('action_type', [
    z.object({
      action_type: z.literal(membershipStoreChangedActionTypeEnum.enum.OWNER_MOVE),
      proxy_uid: uid,
      from_uid: uid,
      to_uid: uid,
    }),
    z.object({
      action_type: z.literal(membershipStoreChangedActionTypeEnum.enum.OWNER_CONNECT),
      proxy_uid: uid,
      from_uid: z.undefined(),
      to_uid: uid,
    }),
    z.object({
      action_type: z.literal(membershipStoreChangedActionTypeEnum.enum.OWNER_DISCONNECT),
      proxy_uid: uid,
      from_uid: uid,
      to_uid: z.undefined(),
    }),
  ]),
});

export const membershipStoreChangedEventLogDataScheme = z.object({
  type: z.literal(MEMBERSHIP_STORE_CHANGED),
  data: membershipStoreChangedActionDataScheme,
});
