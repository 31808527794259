import { find, go } from 'fxjs/es';
import { BpOptionS } from '../../../S/Function/module/BpOptionS.js';
import { UtilNumberS } from '../../../../Util/Number/S/Function/module/UtilNumberS.js';
import { MarketplaceConstantS } from '../../../../Marketplace/S/Constant/module/MarketplaceConstantS.js';

/*키링 최소가격
 최소가격이 바뀌면 변경해줘야 합니다 ! */
export const KEYRING_MIN_PRICE = {
  5046: {
    kr: 3200,
    en: 2.92,
    jp: 313,
  },
  5051: {
    kr: 3700,
    en: 3.37,
    jp: 362,
  },
  5052: {
    kr: 3700,
    en: 3.37,
    jp: 362,
  },
};

export const prices = [
  { area: 4, price: 3200, price_en: 2.91, price_jp: 313 },
  { area: 6, price: 3500, price_en: 3.18, price_jp: 342 },
  { area: 8, price: 3700, price_en: 3.37, price_jp: 362 },
  { area: 9, price: 3900, price_en: 3.55, price_jp: 382 },
  { area: 10, price: 4100, price_en: 3.73, price_jp: 401 },
  { area: 12, price: 4300, price_en: 3.91, price_jp: 421 },
  { area: 14, price: 4500, price_en: 4.09, price_jp: 440 },
  { area: 15, price: 4700, price_en: 4.28, price_jp: 460 },
  { area: 16, price: 4900, price_en: 4.46, price_jp: 479 },
  { area: 18, price: 5300, price_en: 4.82, price_jp: 519 },
  { area: 20, price: 5700, price_en: 5.19, price_jp: 558 },
  { area: 21, price: 6100, price_en: 5.55, price_jp: 597 },
  { area: 22, price: 6400, price_en: 5.82, price_jp: 626 },
  { area: 24, price: 6500, price_en: 5.91, price_jp: 636 },
  { area: 25, price: 6900, price_en: 6.28, price_jp: 675 },
  { area: 27, price: 7300, price_en: 6.64, price_jp: 714 },
  { area: 28, price: 7600, price_en: 6.92, price_jp: 744 },
  { area: 30, price: 7900, price_en: 7.19, price_jp: 773 },
  { area: 32, price: 8200, price_en: 7.46, price_jp: 802 },
  { area: 33, price: 8300, price_en: 7.55, price_jp: 812 },
  { area: 35, price: 8500, price_en: 7.73, price_jp: 832 },
  { area: 36, price: 8800, price_en: 8.01, price_jp: 861 },
  { area: 40, price: 9300, price_en: 8.46, price_jp: 910 },
  { area: 42, price: 9700, price_en: 8.83, price_jp: 949 },
  { area: 44, price: 9900, price_en: 9.01, price_jp: 969 },
  { area: 45, price: 10100, price_en: 9.19, price_jp: 988 },
  { area: 48, price: 10500, price_en: 9.55, price_jp: 1027 },
  { area: 49, price: 10900, price_en: 9.92, price_jp: 1067 },
  { area: 50, price: 11400, price_en: 10.37, price_jp: 1115 },
  { area: 54, price: 11900, price_en: 10.83, price_jp: 1164 },
  { area: 55, price: 12200, price_en: 11.1, price_jp: 1194 },
  { area: 56, price: 12400, price_en: 11.28, price_jp: 1213 },
  { area: 60, price: 12900, price_en: 11.74, price_jp: 1262 },
  { area: 63, price: 13400, price_en: 12.19, price_jp: 1311 },
  { area: 64, price: 13900, price_en: 12.65, price_jp: 1360 },
  { area: 66, price: 14200, price_en: 12.92, price_jp: 1389 },
  { area: 70, price: 14700, price_en: 13.38, price_jp: 1438 },
  { area: 72, price: 15400, price_en: 14.01, price_jp: 1507 },
  { area: 77, price: 15900, price_en: 14.47, price_jp: 1556 },
  { area: 80, price: 16200, price_en: 14.74, price_jp: 1585 },
  { area: 81, price: 16900, price_en: 15.38, price_jp: 1654 },
  { area: 88, price: 18100, price_en: 16.47, price_jp: 1771 },
  { area: 90, price: 18900, price_en: 17.2, price_jp: 1849 },
  { area: 99, price: 20500, price_en: 18.65, price_jp: 2006 },
  { area: 100, price: 20900, price_en: 19.02, price_jp: 2045 },
  { area: 110, price: 22900, price_en: 20.84, price_jp: 2241 },
  { area: 121, price: 24900, price_en: 22.66, price_jp: 2436 },
];
const prices2 = [
  { area: 4, price: 2500, price_en: 2.27, price_jp: 245 },
  { area: 6, price: 2800, price_en: 2.55, price_jp: 274 },
  { area: 8, price: 3000, price_en: 2.73, price_jp: 294 },
  { area: 9, price: 3200, price_en: 2.91, price_jp: 313 },
  { area: 10, price: 3300, price_en: 3, price_jp: 323 },
  { area: 12, price: 3400, price_en: 3.09, price_jp: 333 },
  { area: 14, price: 3600, price_en: 3.28, price_jp: 352 },
  { area: 15, price: 3800, price_en: 3.46, price_jp: 372 },
  { area: 16, price: 3900, price_en: 3.55, price_jp: 382 },
  { area: 18, price: 4100, price_en: 3.73, price_jp: 401 },
  { area: 20, price: 4400, price_en: 4, price_jp: 431 },
  { area: 21, price: 4600, price_en: 4.19, price_jp: 450 },
  { area: 22, price: 4800, price_en: 4.37, price_jp: 470 },
  { area: 24, price: 5000, price_en: 4.55, price_jp: 489 },
  { area: 25, price: 5200, price_en: 4.73, price_jp: 509 },
  { area: 27, price: 5400, price_en: 4.91, price_jp: 528 },
  { area: 28, price: 5600, price_en: 5.1, price_jp: 548 },
  { area: 30, price: 5800, price_en: 5.28, price_jp: 568 },
  { area: 32, price: 6000, price_en: 5.46, price_jp: 587 },
  { area: 33, price: 6100, price_en: 5.55, price_jp: 597 },
  { area: 35, price: 6200, price_en: 5.64, price_jp: 607 },
  { area: 36, price: 6300, price_en: 5.73, price_jp: 616 },
  { area: 40, price: 6400, price_en: 5.82, price_jp: 626 },
  { area: 42, price: 6500, price_en: 5.91, price_jp: 636 },
  { area: 44, price: 6600, price_en: 6.01, price_jp: 646 },
  { area: 45, price: 6700, price_en: 6.1, price_jp: 656 },
  { area: 48, price: 6800, price_en: 6.19, price_jp: 665 },
  { area: 49, price: 6900, price_en: 6.28, price_jp: 675 },
  { area: 50, price: 7000, price_en: 6.37, price_jp: 685 },
  { area: 54, price: 7100, price_en: 6.46, price_jp: 695 },
  { area: 55, price: 7200, price_en: 6.55, price_jp: 705 },
  { area: 56, price: 7300, price_en: 6.64, price_jp: 714 },
  { area: 60, price: 7400, price_en: 6.73, price_jp: 724 },
  { area: 63, price: 7500, price_en: 6.82, price_jp: 734 },
  { area: 64, price: 7700, price_en: 7.01, price_jp: 753 },
  { area: 66, price: 7900, price_en: 7.19, price_jp: 773 },
  { area: 70, price: 8000, price_en: 7.28, price_jp: 783 },
  { area: 72, price: 8100, price_en: 7.37, price_jp: 793 },
  { area: 77, price: 8300, price_en: 7.55, price_jp: 812 },
  { area: 80, price: 8500, price_en: 7.73, price_jp: 832 },
  { area: 81, price: 8700, price_en: 7.92, price_jp: 851 },
  { area: 88, price: 9000, price_en: 8.19, price_jp: 881 },
  { area: 90, price: 9200, price_en: 8.37, price_jp: 900 },
  { area: 99, price: 9600, price_en: 8.74, price_jp: 939 },
  { area: 100, price: 9800, price_en: 8.92, price_jp: 959 },
  { area: 110, price: 10300, price_en: 9.37, price_jp: 1008 },
  { area: 121, price: 10900, price_en: 9.92, price_jp: 1067 },
];

export function makePrice(fitted_size) {
  const is_ness = BpOptionS.needNessPrice();
  const its_area = Math.ceil(fitted_size.width * 0.1) * Math.ceil(fitted_size.height * 0.1);
  const basic_price = find(({ area }) => area == 4)(prices);
  const price = find(({ area }) => area >= its_area)(is_ness ? prices2 : prices);
  return {
    price: price.price - basic_price.price,
    price_en: price.price_en - basic_price.price_en,
    price_jp: price.price_jp - basic_price.price_jp,
  };
}

export function makePriceByService({ fitted_size, service_name }) {
  const size_prices = go(undefined, () => {
    if (service_name === MarketplaceConstantS.Marketplaces.NESS) {
      return prices2;
    }
    return prices;
  });
  const its_area = Math.ceil(fitted_size.width * 0.1) * Math.ceil(fitted_size.height * 0.1);
  const basic_price = find(({ area }) => area == 4)(prices);

  const price = find(({ area }) => area >= its_area)(size_prices);
  return {
    price: UtilNumberS.subDecimal(price.price, basic_price.price),
    price_en: UtilNumberS.subDecimal(price.price_en, basic_price.price_en),
    price_jp: UtilNumberS.subDecimal(price.price_jp, basic_price.price_jp),
  };
}

export function setBpOptionByFittedSize({
  base_products_bp_option_groups,
  selected_option_group,
  fitted_size,
}) {
  const size_bp_option = BpOptionS.selectFittedSizeBpOption({
    base_products_bp_option_groups,
    selected_option_group,
  });
  if (!fitted_size) return;
  if (!size_bp_option) return;
  const price = makePrice(fitted_size);
  size_bp_option.price = price.price;
  size_bp_option.price_en = price.price_en;
  size_bp_option.price_jp = price.price_jp;
  size_bp_option.name = `${fitted_size.width} x ${fitted_size.height} mm`;
  size_bp_option.name_en = `${(fitted_size.width / 2.54).toFixed(2)} x ${(fitted_size.height / 2.54).toFixed(
    2,
  )} inch`;
  size_bp_option.name_jp = size_bp_option.name;
  return price;
}

export function setPriceByFittedSizeWhenStart({
  base_products_bp_option_groups,
  selected_option_group,
  product,
}) {
  if (!product.product_faces2) return;
  const cv_obj = go(
    product.product_faces2.value[0].designs,
    find((cv_obj) => cv_obj.cid == 'c2323'),
  );
  if (!cv_obj) return;
  const { fitted_size } = cv_obj._data;
  setBpOptionByFittedSize({ base_products_bp_option_groups, selected_option_group, fitted_size });
}
