import { OnboardingConstantS } from '../../../marppleShopStudioApp/features/Onboarding/outbound/share';
import { AuthorizationConstantS } from '../../Authorization/outbound/share';
import { StoreConstantS } from '../../Store/outbound/share';

export const EVENT_TYPE = {
  [OnboardingConstantS.CREATOR_STUDIO_ONBOARDING]: OnboardingConstantS.CREATOR_STUDIO_ONBOARDING,
  [StoreConstantS.MEMBERSHIP_STORE_CHANGED]: StoreConstantS.MEMBERSHIP_STORE_CHANGED,
  [AuthorizationConstantS.USER_WITHDRAWAL]: AuthorizationConstantS.USER_WITHDRAWAL,
} as const;

export const ACTION_TYPE = {
  [EVENT_TYPE.CREATOR_STUDIO_ONBOARDING]: OnboardingConstantS.CREATOR_STUDIO_ONBOARDING_ACTION_TYPE,
  [EVENT_TYPE.MEMBERSHIP_STORE_CHANGED]: StoreConstantS.MEMBERSHIP_STORE_CHANGED_ACTION_TYPE,
  [AuthorizationConstantS.USER_WITHDRAWAL]: {},
} as const;
