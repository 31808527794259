import {
  ProductBadgeData,
  ProductBadgeOption,
  ProductBadgeType,
} from '../../../../features/ProductList/type';
import klass from './ProductBadge.module.scss';
import { staticTypoPcMo } from '../../../../shared/typography/typo';
import { compact, join, pipe, values } from '@fxts/core';
import { ProductBadge } from './ProductBadge';

export class ProductBadgeStyleHelper {
  private static readonly THEME_MAP: Readonly<Record<ProductBadgeType, string>> = {
    sold_out: klass.sold_out,
    limited: klass.limited,
    closed: klass.closed,
    quantity_limited: klass.quantity_limited,
    period_limited: klass.period_limited,
    upcoming: klass.upcoming,
    ranking: klass.ranking,
    nft: klass.nft,
    realtime: klass.realtime,
    membership: klass.membership,
  } as const;

  readonly identifier: string;

  private readonly style: {
    theme: string;
    extra_theme: string;
    font: string;
    padding: string;
    ratio: string;
  };

  private readonly icon: {
    base: string;
    size: string;
  };

  constructor(data: ProductBadgeData, option: ProductBadgeOption) {
    this.identifier = klass.product_badge;

    const theme = ProductBadgeStyleHelper.THEME_MAP[data.type];
    const extra_theme = data.type === ProductBadge.Type.ranking && data.value === 1 ? klass.top_rank : '';
    const ratio = option.hide_label ? klass.square : '';

    const font = staticTypoPcMo({
      is_mobile: option.is_mobile,
      pc: 'unica_14_regular',
      mo: 'unica_12_regular',
    });
    const padding =
      option.is_mobile || option.hide_label ? klass.horizontal_padding4 : klass.horizontal_padding8;

    this.style = { theme, extra_theme, font, padding, ratio };

    this.icon = {
      base: klass.icon,
      size: (() => {
        if (option.hide_label) {
          return option.is_mobile ? klass.large : klass.large;
        }
        return option.is_mobile ? klass.small : klass.medium;
      })(),
    };
  }

  get klass(): string {
    return pipe(values(this.style), compact, join(' '));
  }

  get icon_klass(): string {
    return pipe(values(this.icon), compact, join(' '));
  }
}
