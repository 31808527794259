const getColor = (type) => {
  const colors = {
    string: '#39FF14', // 형광 초록
    number: '#00FFFF', // 형광 시안
    boolean: '#FF355E', // 형광 핑크
    object: '#FF00FF', // 형광 마젠타
    array: '#FFD700', // 형광 골드
    null: '#F0FFFF', // 밝은 하늘색
    undefined: '#F0FFFF', // 밝은 하늘색
    function: '#FF69B4', // 밝은 핫핑크
    execution: '#FFFF00', // 형광 노란색
  };
  return colors[type] || '#FFFFFF';
};

export function devConsole(...args) {
  const is_prod = window?.ENV?.is_prod;
  if (is_prod) return;

  const formatValue = (value) => {
    const type = Array.isArray(value) ? 'array' : value === null ? 'null' : typeof value;
    const color = getColor(type);

    let displayValue;
    switch (type) {
      case 'object':
      case 'array':
        try {
          displayValue = JSON.stringify(value, null, 2);
        } catch (e) {
          displayValue = String(value);
        }
        break;
      case 'function': {
        const functionName = value.name || 'anonymous';
        const functionCode = value
          .toString()
          .split('\n')
          .map((line) => {
            if (line.includes('function') || line.includes('=>')) {
              return line.trim();
            }
            const indent = line.match(/^\s*/)[0];
            return `${indent}${line.trim()}`;
          })
          .join('\n');
        displayValue = `[Function: ${functionName}]\n${functionCode}`;
        break;
      }
      default:
        displayValue = String(value);
    }

    return [
      `%c${displayValue}`,
      `color: ${color}; font-weight: bold;${type === 'object' || type === 'array' ? ' font-family: monospace;' : ''}`,
    ];
  };

  args.forEach((arg, index) => {
    const [formattedValue, style] = formatValue(arg);
    if (index === 0) {
      console.group('%c[DEV]', 'color: #17a2b8; font-weight: bold; font-size: 12px;');
    }
    console.log(formattedValue, style);
  });

  console.groupEnd();
}

export async function measureExecutionTime(fn, logName = null) {
  if (window?.ENV?.is_prod) return fn();

  const label = logName || fn.name || 'anonymous';

  const start = performance.now();
  const result = await (async () => {
    try {
      const fnResult = fn();
      if (fnResult instanceof Promise || fn.constructor.name === 'AsyncFunction') {
        return await fnResult;
      }
      return fnResult;
    } catch (error) {
      console.error('%cExecution Error:', 'color: #FF0000; font-weight: bold;', error);
      throw error;
    }
  })();
  const end = performance.now();
  const executionTime = end - start;

  console.log(
    '%c[DEV]%c %s: %s',
    'color: #17a2b8; font-weight: bold; font-size: 12px;',
    `color: ${getColor('execution')}; font-weight: bold;`,
    label,
    `${executionTime.toFixed(1)}ms`,
  );

  return result;
}
