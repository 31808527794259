import { add, constant, go, map, range, reduce, strMap } from 'fxjs/es';
import { CREW_DOMAIN_NAME } from '../../../../Creator/Crew/S/constant.js';
import { NessOutboundConstantS } from '../../../../Ness/Outbound/S/Constant/module/NessOutboundConstantS.js';
import {
  EMOJI_REGX,
  PRODUCT_NAME_BLACK_LIST_REGX,
  SPECIAL_CHARACTERS_REGX,
} from '../../../../Util/S/constant.js';
import { MShopCoreConstantS } from '../../../Core/S/Constant/module/MShopCoreConstantS.js';
import { MShopUtilConstantS } from '../Constant/module/MShopUtilConstantS.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';

export const APP_RESET_NAVIGATE_TYPE = {
  HOME: 'Home',
  LIVE: 'Live',
  REVIEW: 'Community',
  SEARCH: 'Search',
  MYPAGE: 'Mypage',
};

// FIXME: 앱 공지 필요할 때 주석 제거 (아래 코드는 예시입니다)
// eslint-disable-next-line no-unused-vars
const NOTICE = {
  id: 'NOTICE_2023060700',
  title: { kr: '공지', en: 'Notice', jp: '通知する' },
  description: { kr: '공지입니다.', en: 'This is notice.', jp: 'これは通知です' },
  iconFilePath:
    'https://s3.marpple.co/files/u_14345/2021/6/original/913490c997da447298f1b0c53cda2dc4ac0daf921.png',
  buttons: [
    {
      text: { kr: '닫기', en: 'Close', jp: '閉まる' },
      close: true,
      setDoNotShowLater: true,
    },
    {
      text: { kr: '이동', en: 'Go', jp: '行く' },
      url: {
        kr: 'https://www.marpple.com/kr',
        en: 'https://www.marpple.com/en',
        jp: 'https://www.marpple.com/jp',
      },
      close: true,
      setDoNotShowLater: true,
    },
  ],
};

const NEED_UPDATE_ALERT_IMAGE = {
  FEATURE:
    'https://s3.marpple.co/files/u_2308668/2025/1/original/5354df762e1efd5f70692513e3623f5f19e8c05e1.png',
  STABILITY:
    'https://s3.marpple.co/files/u_2308668/2025/1/original/71ebeced7d0a8d483f154664f3ee9b735c1345981.png',
};

// MEMO: 버전 기록은 아래 링크에서 확인 가능합니다.
// MEMO: https://www.notion.so/marpple/07af015590404b6eb997860af9d718ae?pvs=4#29ceeea7c57a47308a95a99be8a38a8b

const NEED_UPDATE = {
  version: '2.1.2', // 해당 버전 "미만"일 때 강제 업데이트 화면이 노출됩니다.
  title: {
    kr: '마플샵 업데이트 안내',
    en: 'MarppleShop Update',
    jp: 'MarppleShop アップデート',
  },
  description: {
    kr: '보다 안정적인 서비스 이용을 위해\\n마플샵 앱을 최신 버전으로 업데이트해 주세요!',
    en: 'For a more stable service experience,\\nplease update the MarppleShop app\\nto the latest version!',
    jp: 'より安定したサービスをご利用いただくために、\\nマプルショップアプリを最新バージョンに\\nアップデートしてください！',
  },
  alert_img: NEED_UPDATE_ALERT_IMAGE.STABILITY,
};

const RECOMMEND_UPDATE = {
  version: '2.1.0', // 해당 버전 "미만"일 때 권장 업데이트 화면이 노출됩니다.
  title: {
    kr: '마플샵 업데이트 안내',
    en: 'MarppleShop Update',
    jp: 'MarppleShop アップデート',
  },
  description: {
    kr: '마플샵과 마플의 통합 회원으로 SNS 계정을\\n연결하고 편리하게 회원가입할 수 있도록\\n앱이 업데이트 되었습니다.\\n마플샵의 새로운 기능을 만나보세요!',
    en: 'MarppleShop and Marpple have\\nintegrated their memberships!\\nNow, you can easily sign up by linking your\\nSNS account. Check out the new features of\\nMarppleShop with the latest app update!',
    jp: 'MarppleShopとMarppleが統合されました！\\nSNSアカウントを連携して、簡単に会員登録が\\nできるようにアプリがアップデートされました。\\nMarppleShopの新機能をぜひお試しください！',
  },
  update_type: NEED_UPDATE_ALERT_IMAGE.FEATURE,
};

export const isNeedUpdateForDtg = (version) => {
  if (!version) return true;
  return compareVersion(version, '1.6.3') === -1;
};

const isNeedUpdate = (version) => {
  if (!version) return true;
  return compareVersion(version, NEED_UPDATE.version) === -1;
};

const isRecommendUpdate = (version) => {
  if (!version) return true;
  return compareVersion(version, RECOMMEND_UPDATE.version) === -1;
};

export const notice = (version, platform) => {
  // 1.6.2 이상 버전에서만 공지 화면 노출이 가능합니다.
  // 1.6.2 미만 버전 대응을 위해 추가한 코드이니 `NEED_UPDATE.version`이 1.6.2 이상으로 올라간다면 아래 if 문을 삭제해주세요.
  // 그 전까지 절대 삭제하시면 안 됩니다!
  if (compareVersion(version, '1.6.2') === -1) {
    return null;
  }
  // FIXME: 앱 공지 필요할 때 주석 제거
  // if (platform === 'Android' || platform === 'iOS') {
  //   return JSON.stringify({
  //     ...NOTICE,
  //     ...getUpdate(version, platform),
  //   });
  // }
  return null;
};

// eslint-disable-next-line no-unused-vars
const getUpdate = (version, platform) => {
  if (isNeedUpdate(version)) {
    return { needUpdate: needUpdate(version, platform) };
  }
  if (isRecommendUpdate(version)) {
    return { recommendUpdate: recommendUpdate(version, platform) };
  }
  return {};
};

export const needUpdate = (version, platform) => {
  try {
    if (platform === 'Android' || platform === 'iOS') {
      if (isNeedUpdate(version)) {
        return { platform, ...NEED_UPDATE };
      }
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const recommendUpdate = (version, platform) => {
  try {
    if (platform === 'Android' || platform === 'iOS') {
      if (isRecommendUpdate(version)) {
        return { platform, ...RECOMMEND_UPDATE };
      }
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const is_sharable = (version, platform) => {
  return !(platform === 'Android' && version < '1.1.0');
};

/*
 * 나중에 추상화
 * */
export const getSellerWording = (crew, is_seller) =>
  crew.domain_name == CREW_DOMAIN_NAME.NAVER_WEBTOON ||
  crew.domain_name == CREW_DOMAIN_NAME.NAVER_WEBTOON_FAKE
    ? T('mshop::Menu::작품')
    : is_seller
      ? T('Seller')
      : T('mshop::Menu::Creator');

/**
 * version을 비교하는 함수
 * @param {string} version1
 * @param {string} version2
 * @return {number}
 * `version1` > `version2`이면 `1`
 *
 * `version1` < `version2`이면 `-1`
 *
 * `version1` = `version2`이면 `0`
 */
export const compareVersion = (version1, version2) => {
  const v1 = parseVersion(version1);
  const v2 = parseVersion(version2);

  if (v1.major > v2.major) return 1;
  if (v1.major < v2.major) return -1;

  if (v1.minor > v2.minor) return 1;
  if (v1.minor < v2.minor) return -1;

  if (v1.patch > v2.patch) return 1;
  if (v1.patch < v2.patch) return -1;

  return 0;
};

/**
 * @typedef {Object} ParsedVersion
 * @property {number} major
 * @property {number} minor
 * @property {number} patch
 */

/**
 *
 * @param {string} version
 * @return ParsedVersion
 */
export const parseVersion = (version) => {
  const versionFormat = /^(\d+).(\d+).(\d+)$/;
  const match = version.match(versionFormat);
  if (!match) {
    throw new Error('version is not valid.');
  }

  const [, major, minor, patch] = match;
  return {
    major: Number.parseInt(major),
    minor: Number.parseInt(minor),
    patch: Number.parseInt(patch),
  };
};

export const isPermitSpecialCharProductName = (value) => {
  if (SPECIAL_CHARACTERS_REGX.test(value) || EMOJI_REGX.test(value)) {
    return false;
  }
  return true;
};

export const isNotAllowSpecialCharProductName = (value) => {
  const _value = value.replace(/&amp;/g, '&');
  return SPECIAL_CHARACTERS_REGX.test(_value) || EMOJI_REGX.test(_value);
};

export const moneySign = (price) => {
  if (T.lang === 'kr') {
    return `${price} 원`;
  }
  if (T.lang === 'en') {
    return `$ ${price}`;
  }
  if (T.lang === 'jp') {
    return `¥ ${price}`;
  }
};

export const getColorInlineStyle = ({ style_props, color }) => {
  if (!color || !style_props || !style_props.length) return '';

  return go(
    style_props,
    map((target) => `${target}: ${color};`),
    reduce(add),
    (styles) => `style="${styles}"`,
  );
};

/**
 * @param {object} plus_crew
 * @param {string} plus_crew.brand_color
 * @param {string} plus_crew.menu_color
 * @param {boolean} isApp
 * @return {{menu_color: string, brand_color: string}}
 */
export const getCrewBrandColors = (plus_crew, isApp) => {
  const is_ness = UtilS.isNessApp();
  if (is_ness) {
    return {
      brand_color: NessOutboundConstantS.colors.blue,
      menu_color: NessOutboundConstantS.colors.black,
    };
  }

  const DEFAULT_COLOR = {
    brand_color: MShopCoreConstantS.colors.blue,
    menu_color: MShopCoreConstantS.colors.white,
  };
  const is_white = plus_crew?.brand_color?.toLowerCase() === '#ffffff';

  if (!plus_crew?.brand_color || isApp || is_white) return DEFAULT_COLOR;

  return {
    brand_color: plus_crew.brand_color,
    menu_color: plus_crew.menu_color,
  };
};

/**
 *
 * @param {boolean} _case
 * @param {any} returnVal
 */
export const returnIf = (_case, returnVal) => {
  const is_fn = typeof returnVal == 'function';
  if (_case) return is_fn ? returnVal() : returnVal;
};

export const getCustomerServiceInfo = (crew_id) => {
  if (crew_id == MShopUtilConstantS.NBOX_CREW_ID) {
    return {
      tel: '1566-9437',
      sms: '15669437',
      email: 'help@marpple.com',
    };
  }
  if (UtilS.isNessApp()) {
    return {
      tel: '1566-7960',
      email: 'nexon@marpple.com',
    };
  }
  return {
    tel: '1566-5496',
    sms: '15665496',
    email: 'cs@marppleshop.com',
  };
};

export const classNames = (...classes) => {
  return classes
    .map((cls) => {
      const _type = typeof cls;
      const is_array = Array.isArray(cls);

      if (is_array) return cls.join(' ');
      if (_type === 'string') return cls;
      if (_type === 'object' && !is_array) {
        const _cs = [];
        for (const key in cls) {
          if (cls[key]) _cs.push(key);
        }
        return _cs.join(' ');
      }
      return '';
    })
    .join(' ');
};

export const makeMaskingBlur = (val, is_masking_blur) => {
  if (is_masking_blur && val) {
    const len = Math.min(Math.ceil(val.length / 4), 3);
    return val.substr(0, len) + strMap(constant('*'), range(val.length - len));
  } else {
    return val;
  }
};

export const isProductNameBlackList = (value) => {
  return PRODUCT_NAME_BLACK_LIST_REGX.test(value.replace(/\s/g, ''));
};
