export const Marketplaces = {
  NESS: 'NESS',
  MARPPLE_SHOP: 'MARPPLE_SHOP',
};

export const MARKETPLACE_NESS_KLASS = 'marketplace_ness_klass';

export const GHOST_CREW_MAPPING = {
  NESS: 539,
  MARPPLE_SHOP: 1,
};
