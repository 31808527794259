import { type Html, html, type UnsafeHtml, type View } from 'rune-ts';
import { Lang } from '../typography';
import { delay, isNil, isNumber, last, map, omitBy, pipe, pipe1, reduce, split, toArray } from '@fxts/core';
import querystring from 'query-string';
import { MShopUtilConstantS } from '../../../../modules/MShop/Util/S/Constant/module/MShopUtilConstantS';

export const classes = (...classes) => {
  return classes
    .map((cls) => {
      const _type = typeof cls;
      const is_array = Array.isArray(cls);
      if (_type === 'string') return cls;
      if (_type === 'object' && !is_array) {
        const _cs: string[] = [];
        for (const key in cls) {
          if (cls[key]) {
            _cs.push(key);
          }
        }
        return _cs.join(' ');
      }
      if (Array.isArray(cls)) {
        return cls.join(' ');
      }
      return '';
    })
    .join(' ');
};

export const htmlIf = (html: string | Html | UnsafeHtml | View, condition: boolean) => {
  return condition ? html : '';
};

export const doIf = (func: () => string | Html | UnsafeHtml | View, condition: boolean) => {
  return condition ? func() : '';
};

export const htmlIfElse = (
  condition: boolean,
  html: string | Html | UnsafeHtml | View,
  else_html?: string | Html | UnsafeHtml | View,
) => {
  return condition ? html : else_html ?? '';
};

export const changeUrlLang = (url: string, lang: Lang) => {
  const received_url = new URL(url);
  // slash(/) 로 시작하고 en kr jp 중에 하나를 가지고 / 가 붙거나 끝나거나
  const lang_regex = /^(\/(en|kr|jp))(?=\/|$)/;
  received_url.pathname = received_url.pathname.replace(lang_regex, `/${lang}`);
  return received_url.toString();
};

export const ptr = (size: number, root_size = 16) => {
  return size / root_size + 'rem';
};

export const intl = (key: string, obj: { [key: string]: any } | undefined) => {
  if (!obj) return '';
  return obj[key + _en] || '';
};

export const debounce = <F extends (...args: Parameters<F>) => ReturnType<F>>(f: F, time: number) => {
  let i = 0;
  return function _debounce(...args: Parameters<F>) {
    return delay(time, ++i).then((id) => (id === i ? f(...args) : void 0));
  };
};

export const throttleWithLastExecute = <
  F extends (...args: Parameters<F>) => ReturnType<F> | Promise<ReturnType<F>>,
>(
  f: F,
  time: number,
) => {
  let block = false;
  const throttleFunction = async function (...args: Parameters<F>) {
    if (block) return;
    block = true;
    const res = await f(...args);
    pipe1(delay(time, res), () => (block = false)).finally(() => (block = false));
    return res;
  };

  let setTime;
  return async (...args: Parameters<F>) => {
    if (setTime) clearTimeout(setTime);
    if (block) setTime = setTimeout(() => f(...args), time);
    await throttleFunction(...args);
  };
};

export const isAllCrewById = (crew_id: number) => crew_id == 1;
export const isSoopCrewById = (crew_id: number) => crew_id == MShopUtilConstantS.SOOP_MARPPLESHOP_CREW_ID;

export const makeApiUrl = (path: string, obj: Record<any, string | number>): string =>
  pipe(
    path,
    split('/'),
    map((a) => {
      if (a.startsWith(':')) {
        const obj_val = obj[a.slice(1)];
        const val = isNumber(obj_val) ? obj_val.toString() : obj_val;
        return [a, val];
      } else {
        return [a, null];
      }
    }),
    map(([a, b]) => b || a),
    toArray,
    (xs) => xs.join('/'),
  );

export const string = <T>(iter: Iterable<T>) => {
  return reduce((a, b) => `${a}${b}`, '', iter);
};

export const strMap = function strMap<A, B>(f: (a: A) => B, iter: Iterable<A>) {
  return html.preventEscape(string(map(f, iter)));
};

export const parseQuery = (
  query: string,
  option: {
    parse_number?: boolean;
    parse_boolean?: boolean;
  } = {},
) => {
  return querystring.parse(query, {
    parseNumbers: option.parse_boolean ?? true,
    parseBooleans: option.parse_number ?? true,
  });
};

/**
 * URL의 검색 문자열을 파싱하고 새로운 파라미터로 업데이트하여 반환합니다.
 *
 * @param search - 기존 URL의 검색 문자열. '?'를 포함할 수도 있고 포함하지 않을 수도 있습니다.
 * @param new_params - 추가하거나 업데이트할 새로운 파라미터 객체.
 * @param preserve_prev - 이전 파라미터를 보존할지 여부. 기본값은 true입니다.
 * @returns - 업데이트된 URL의 검색 문자열.
 *
 * @example
 * getUpdateUrlParams('?foo=1&bar=2', { foo: 3, baz: 4 });
 * // returns 'foo=3&bar=2&baz=4'
 */
export const getUpdateUrlParams = (
  search: string,
  new_params: Record<string, string | number | undefined | null>,
  preserve_prev = true,
): string =>
  pipe(
    querystring.parse(search[0] === '?' ? search.slice(1) : search),
    (prev) => (preserve_prev ? { ...prev, ...new_params } : new_params),
    omitBy(([, v]) => isNil(v)),
    querystring.stringify,
  );

/**
 * 주어진 URL로 브라우저의 히스토리를 교체합니다.
 *
 * @param {string} url - 교체할 새로운 URL.
 * @returns {void}
 *
 * @example
 * replaceUrl('https://example.com/new-page');
 */
export const replaceUrl = (url: string): void => {
  if (url === location.href) return;

  history.replaceState(history.state, '', url);
};

/**
 * 주어진 URL에 새로운 파라미터를 추가하거나 업데이트하여 반환합니다.
 *
 * @param {string} url - 파라미터를 업데이트할 URL.
 * @param {Record<string, string | number | null | undefined>} newParams - 추가하거나 업데이트할 새로운 파라미터 객체.
 * @param preservePrev - 이전 파라미터를 보존할지 여부. 기본값은 true입니다.
 * @returns {string} - 업데이트된 URL.
 *
 * @example
 * updateUrlParams('https://example.com?page=1', { page: 2, sort: 'asc' });
 * // returns 'https://example.com?page=2&sort=asc'
 */
export const updateUrlParams = (
  url: string,
  newParams: Record<string, string | number | null | undefined>,
  preservePrev = true,
): string => {
  const [baseUrl, queryString] = url.split('?');
  const updatedQueryString = getUpdateUrlParams(queryString || '', newParams, preservePrev);

  return `${baseUrl}${updatedQueryString ? '?' + updatedQueryString : ''}`;
};

export const iframeToUrl = (iframe: string) => {
  const isUrl = (url: string) => /https:\/\//g.test(url);
  const replaceTwitchUrl = (url: string) => url.replace('www.example.com', 'marpple.shop');

  const result_url = last(iframe.match(/src\s*=\s*"(.+?)"/) ?? []) || iframe;

  return isUrl(result_url) ? replaceTwitchUrl(result_url) : '';
};

export const makeYoutubeThumbUrl = (youtube_id = '') => {
  const thumb_url = 'https://img.youtube.com/vi/';
  const max_file_name = '/maxresdefault.jpg';
  return thumb_url + youtube_id.split('?')[0] + max_file_name;
};

export const getYoutubeId = (url: string) => {
  const embed_regexp = /embed\/([^&]+)\??/;
  const match_regexp = /watch\?v=([^&]+)\??/;
  const short_regexp = /shorts\/([^&|?]+)\??/;

  if (embed_regexp.test(url)) {
    const [, youtube_id] = url.match(embed_regexp)!;
    return youtube_id;
  }
  if (match_regexp.test(url)) {
    const [, youtube_id] = url.match(match_regexp)!;
    return youtube_id;
  }
  if (short_regexp.test(url)) {
    const [, youtube_id] = url.match(short_regexp)!;
    return youtube_id;
  }

  return url.replace(/(https?:\/\/(www\.)?)?youtube\.com\/watch\?v=|(https?:\/\/(www\.)?)?youtu\.be\//, '');
};

export function replaceNewlinesWithBr(text?: string | null): string {
  return text ? text.replace(/\n/g, '<br/>') : '';
}

/**
 * Shuffles an array in place and returns it.
 *
 * @template T - The type of elements in the array.
 * @param {T[]} array - The array to shuffle.
 * @returns {T[]} - The shuffled array.
 */
export function shuffleArray<T>(array: T[]): T[] {
  // Create a copy of the array to avoid mutating the original array
  const result = array.slice();
  for (let i = result.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [result[i], result[j]] = [result[j] as T, result[i] as T];
  }
  return result;
}
