export const product_tag = {
  sold_out: 'sold_out',
  quantity_limited: 'quantity_limited',
  period_limited: 'period_limited',
  upcoming: 'upcoming',
  ranking: 'ranking',
  nft: 'nft',
} as const;

export const product_badge = {
  sold_out: 'sold_out',
  limited: 'limited',
  closed: 'closed',
  quantity_limited: 'quantity_limited',
  period_limited: 'period_limited',
  upcoming: 'upcoming',
  ranking: 'ranking',
  nft: 'nft',

  /**
   * 실시간
   */
  realtime: 'realtime',

  /**
   * 멤버십
   */
  membership: 'membership',
} as const;

// export type ProductListSortKey = 'price_low_to_high' | 'price_high_to_low' | 'newest' | 'popular' | 'many_reviews';

export const PRODUCT_LIST_SORT = {
  price_low_to_high: 'price_low_to_high', // 낮은 가격순
  price_high_to_low: 'price_high_to_low', // 높은 가격순
  newest: 'newest', // 최신순
  popular: 'popular', // 인기순
  many_reviews: 'many_reviews', // 리뷰순
} as const;

export type ProductListSortKey = keyof typeof PRODUCT_LIST_SORT;

export type ProductListSortOptionMap = {
  [K in keyof typeof PRODUCT_LIST_SORT]: {
    key: K;
    value: (typeof PRODUCT_LIST_SORT)[K];
    name: string;
  };
};

export type ProductListSortOption = ProductListSortOptionMap[keyof typeof PRODUCT_LIST_SORT];

export const userLikedProductListLimit = 20;

export const CREATOR_SHOP_SORT = {
  ...PRODUCT_LIST_SORT,
  featured: 'featured', // 추천순
} as const;

type CreatorSortOptionType<
  T extends typeof CREATOR_SHOP_SORT = typeof CREATOR_SHOP_SORT,
  V extends T[keyof T] = T[keyof T],
> = {
  key: V;
  value: V;
  name: string;
};

export const CREATOR_SHOP_SORT_OPTIONS: CreatorSortOptionType[] = [
  {
    key: CREATOR_SHOP_SORT.featured,
    value: CREATOR_SHOP_SORT.featured,
    get name() {
      return ET('mps2::product::sort::featured');
    },
  },
  {
    key: CREATOR_SHOP_SORT.newest,
    value: CREATOR_SHOP_SORT.newest,
    get name() {
      return ET('mps2::product::sort::newest');
    },
  },
  {
    key: CREATOR_SHOP_SORT.popular,
    value: CREATOR_SHOP_SORT.popular,
    get name() {
      return ET('mps2::product::sort::popular');
    },
  },
  {
    key: CREATOR_SHOP_SORT.price_low_to_high,
    value: CREATOR_SHOP_SORT.price_low_to_high,
    get name() {
      return ET('mps2::product::sort::price_low_to_high');
    },
  },
  {
    key: CREATOR_SHOP_SORT.price_high_to_low,
    value: CREATOR_SHOP_SORT.price_high_to_low,
    get name() {
      return ET('mps2::product::sort::price_high_to_low');
    },
  },
  {
    key: CREATOR_SHOP_SORT.many_reviews,
    value: CREATOR_SHOP_SORT.many_reviews,
    get name() {
      return ET('mps2::product::sort::many_reviews');
    },
  },
] as const;

export const LIMITED_PRODUCT_SORT = {
  ...PRODUCT_LIST_SORT,
  featured: 'featured', // 추천순 (최신순 정렬 및 품절 후순위)
} as const;
