import { z } from 'zod';
import { USER_WITHDRAWAL } from '../constant';

const uid = z.string();

export const userWithdrawalActionDataScheme = z.object({
  uid,
  user_id: z.number(),
});

export const userWithdrawalEventLogDataScheme = z.object({
  type: z.literal(USER_WITHDRAWAL),
  data: userWithdrawalActionDataScheme,
});
