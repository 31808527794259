import { find, go } from 'fxjs/es';
import { VectorEditorConstantS } from '../../../VectorEditor/S/Constant/module/VectorEditorConstantS.js';
import { BpOptionKeyRingS } from '../../KeyRing/S/Function/module/BpOptionKeyRingS.js';
import { UtilNumberS } from '../../../Util/Number/S/Function/module/UtilNumberS.js';
import { BpOptionAcrylicFigureS } from '../../AcrylicFigure/S/Function/module/BpOptionAcrylicFigureS.js';
import { BpOptionKeyRingConstantS } from '../../KeyRing/S/Constant/module/BpOptionKeyRingConstantS.js';
import { BaseProductAdditionalPriceRateConstantS } from '../../../BaseProduct/AdditionalPriceRate/S/Constant/module/BaseProductAdditionalPriceRateConstantS.js';

function setPrice(product_color, service_name, sizePriceFund) {
  const selected_bp_option = product_color._.selected_option_group;
  const legacy_selected_keyring_option = go(
    /* bp_option 테이블의 price 를 더해줘야 한다. 레거시 */
    selected_bp_option._.bp_options,
    find((bp_option) =>
      BaseProductAdditionalPriceRateConstantS.legacy_keyring_bp_options.find(({ id }) => id == bp_option.id),
    ),
    (bp_option) => {
      if (bp_option) {
        return {
          price: bp_option.price,
          price_en: bp_option.price_en,
          price_jp: bp_option.price_jp,
        };
      }
    },
  ) || {
    price: 0,
    price_en: 0,
    price_jp: 0,
  };
  const cv_obj = go(
    product_color.product_faces2.value[0].designs,
    find((cv_obj) => cv_obj.cid === 'c2323'),
  );
  const basic_price = {
    price: product_color._.base_product.price,
    price_en: Number(product_color._.base_product.price_en),
    price_jp: product_color._.base_product.price_jp,
  };

  const fitted_size = cv_obj._data.fitted_size;
  const size_price = sizePriceFund({ fitted_size, basic_price, service_name });
  cv_obj._data.price = UtilNumberS.addDecimal(legacy_selected_keyring_option.price, size_price.price);
  cv_obj._data.price_en = UtilNumberS.addDecimal(
    legacy_selected_keyring_option.price_en,
    size_price.price_en,
  );
  cv_obj._data.price_jp = UtilNumberS.addDecimal(
    legacy_selected_keyring_option.price_jp,
    size_price.price_jp,
  );
}

export const setAcrilicSizePrice = (product_color, service_name) => {
  if (!product_color._.base_product.maker_type) throw new Error('maker_type 이 필요합니다.');
  if (!product_color._.selected_option_group)
    throw new Error('selected_option_group associate 에 포함 안되어 있음');
  if (product_color._.base_product.maker_type === VectorEditorConstantS.ACRYLIC_FIGURE_EDITOR) {
    setPrice(product_color, service_name, BpOptionAcrylicFigureS.makePriceByService);
  }
  if (product_color._.base_product.maker_type === VectorEditorConstantS.KEYRING_EDITOR) {
    const is_free = product_color._.selected_option_group.bp_option_ids.includes(
      BpOptionKeyRingConstantS.FREE_BP_OPTION_ID,
    );
    if (!is_free) return;
    setPrice(product_color, service_name, BpOptionKeyRingS.makePriceByService);
  }
};
