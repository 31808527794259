import { html, View } from 'rune-ts';
import { default_plus_img } from '../../../../features/Creator/constant';
import { PostMessageNavigate } from '../../../../shared/app/navigate';
import { dataStr } from '../../../../shared/util/dataStr';
import c from './PlusCreatorLogo.module.scss';
import { htmlIf } from '../../../../shared/util';
import { typo } from '../../../../shared/typography/typo';

export interface PlusCreatorLogoProps {
  plus?: {
    logo_url: string | null;
    name: string;
    link?: string;
    navigate?: PostMessageNavigate;
  };
  creator?: {
    profile_url?: string | null;
    logo_url?: string | null;
    name: string | null;
    link?: string;
    navigate?: PostMessageNavigate;
  };
  is_mobile: boolean;
  theme?: 'dark' | 'white';
}

export class PlusCreatorLogo extends View<PlusCreatorLogoProps> {
  static isSurrounded(props: { plus: boolean; creator: boolean }) {
    return !!(props.plus && props.creator);
  }

  static isEmpty(props: { plus: boolean; creator: boolean }) {
    return !props.plus && !props.creator;
  }

  changeTheme(theme: PlusCreatorLogoProps['theme'], instant: boolean) {
    const element = this.element();
    const logo_container_element = element.querySelector(`.${c.logo_container}`) as HTMLElement;
    this.data.theme = theme;

    if (theme == 'white') {
      logo_container_element.classList.remove(c.dark);
    } else {
      logo_container_element.classList.add(c.dark);
    }

    if (instant) {
      element.classList.add(c.instant_transition);
      element.offsetHeight;
      element.classList.remove(c.instant_transition);
    }
  }

  override template() {
    const { plus, creator, is_mobile } = this.data;
    const theme_class = this.data.theme == 'dark' ? c.dark : '';

    if (PlusCreatorLogo.isEmpty({ plus: !!plus, creator: !!creator })) return html`<span></span>`;

    const surrounded = PlusCreatorLogo.isSurrounded({ plus: !!plus, creator: !!creator });
    const without_bg = creator && !plus && !is_mobile;

    return html`
      <div class="${c.plus_creator_logo}">
        <div
          class="${c.logo_container} ${htmlIf(c.surrounded, surrounded)} ${theme_class} ${without_bg
            ? c.no_bg
            : ''}"
        >
          ${plus
            ? html`<${plus.link ? 'a' : 'div'}
                class="${c.logo_round_box}"
                ${plus.link ? html`href="${plus.link}"` : ''}
                ${plus.navigate ? html`data-post-message="${dataStr(plus.navigate)}"` : ''}
                ><img class="${c.plus_logo_img}" src="${
                plus.logo_url ?? default_plus_img
              }" alt="creator group logo"
              /></${plus.link ? 'a' : 'div'}>`
            : ''}
          ${!creator
            ? ''
            : is_mobile || plus
            ? html`
                <${creator.link ? 'a' : 'div'}
                  class="${c.logo_round_box}"
                  ${creator.link ? html`href="${creator.link}"` : ''}
                  ${creator.navigate ? html`data-post-message="${dataStr(creator.navigate)}"` : ''}
                  ><img
                    class="${c.profile_img}"
                    src="${
                      creator.profile_url ||
                      'https://s3.marpple.co/files/u_1165777/2024/4/original/8ff2b4e88ce4d2eabce1474c26a20de0220d2d981.png'
                    }"
                    alt="creator profile"
                /></${creator.link ? 'a' : 'div'}>
              `
            : creator.logo_url
            ? html`<a
                ${creator.link ? html`href="${creator.link}"` : ''}
                ${creator.navigate ? html`data-post-message="${dataStr(creator.navigate)}"` : ''}
                class="${c.logo_rect_box}"
                ><img class="${c.logo_img}" src="${creator.logo_url}" alt="creator logo"
              /></a> `
            : html`<a
                ${creator.link ? html`href="${creator.link}"` : ''}
                ${creator.navigate ? html`data-post-message="${dataStr(creator.navigate)}"` : ''}
                class="${c.creator_name} ${typo('20_bold')}"
                >${html.preventEscape(creator.name)}</a
              >`}
        </div>
        ${plus && !creator && !is_mobile
          ? html`<a href="/${ET.lang}"
              ><span class="${typo('20_bold')}">${html.preventEscape(plus.name)}</span></a
            >`
          : ''}
      </div>
    `;
  }
}
