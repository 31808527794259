import { z } from 'zod';
import { CREATOR_STUDIO_ONBOARDING, CREATOR_STUDIO_ONBOARDING_ACTION_TYPE } from '../constant';

export const creatorStudioOnboardingActionTypeEnum = z.nativeEnum(CREATOR_STUDIO_ONBOARDING_ACTION_TYPE);

export const creatorStudioOnboardingActionDataScheme = z.object({
  action_type: creatorStudioOnboardingActionTypeEnum,
  /**
   * @description
   * 스케쥴러나 이벤트 처리 로직에서 확인 후에 CREATOR_STUDIO_ONBOARDING_LAST_ACTION_INDEX 에 도달한 경우 완료되었음을 표시하게 합니다.
   * */
  action_index: z.number(),
  last_action_index: z.number().positive(),
  user_id: z.number().positive(),
  point: z.number(),
});

export const creatorStudioOnboardingEventLogDataScheme = z.object({
  type: z.literal(CREATOR_STUDIO_ONBOARDING),
  data: creatorStudioOnboardingActionDataScheme,
});
