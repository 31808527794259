import { resetUserCounts } from '../../../../modules/MPay/Cart/F/fs';
import { UserJwtF } from '../../../../modules/User/Jwt/F/Function/module/UserJwtF';

const checkDifferentUser = (bu: any, u: any) => bu.id && bu.id == u.id && bu.type == u.type;

async function afterSessionCheck(user: any) {
  if (!user) return;
  if (checkDifferentUser(box.sel('is_user'), user)) return resetUserCounts();
  await UserJwtF.loginMessage(user);
  return window.location.reload();
}

function checkParse(response: string) {
  try {
    return JSON.parse(response);
  } catch (e) {
    return 'Parse Error';
  }
}

export const isSessionCheck = function () {
  const xhttp = new XMLHttpRequest();
  xhttp.open('GET', '/@api/mp/is_session', true);
  xhttp.send();
  xhttp.onreadystatechange = function recur() {
    if (!window._p.box) return setTimeout(recur, 10);
    if (!window.box) {
      window.box = window._p.box(window.ENV.box);
      delete window.ENV.box;
    }
    if (this.readyState == 4 && this.status == 200) {
      const html = document.getElementsByTagName('html')[0];
      const user = checkParse(this.response);
      if (user == 'Parse Error') return;
      const is_session = !!user;
      if (window.box && user && window.box.sel('is_user->id') && user.id !== window.box.sel('is_user->id'))
        return location.reload();
      if (!is_session && html?.getAttribute('is_session') == 'true') return location.reload();
      if (is_session && user) return afterSessionCheck(user);
    }
  };
};
