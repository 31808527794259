import Decimal from 'decimal.js';
import { reduce } from '@fxts/core';

export type StringNumber = string | number;
export type StringNumberDecimal = StringNumber | Decimal;

function makeBigDecimalCalc(method: 'add' | 'sub' | 'mul' | 'div') {
  function calc(a: StringNumberDecimal): (...stringNumbers: StringNumberDecimal[]) => string;
  function calc(...args: StringNumberDecimal[]): string;
  function calc(...args: StringNumberDecimal[] | [StringNumberDecimal]) {
    //
    function core(first: StringNumberDecimal, ...stringNumbers: StringNumberDecimal[]) {
      try {
        const d_a = first instanceof Decimal ? first : new Decimal(first || 0);
        return reduce((acc, stringNumber) => acc[method](stringNumber), d_a, stringNumbers).toString();
      } catch (err) {
        console.error(`${method}: ${first}, ${stringNumbers}`);
        throw err;
      }
    }

    if (args.length === 1) {
      // 인자가 하나일 때는 함수를 리턴
      return (...rest: StringNumber[]) => core(args[0], ...rest);
    } else {
      // 인자가 여러 개일 때는 문자열을 리턴
      return core(args[0], ...(args.slice(1) as StringNumber[]));
    }
  }

  return calc;
}

export const addBigDecimal = makeBigDecimalCalc('add');
export const subBigDecimal = makeBigDecimalCalc('sub');
export const mulBigDecimal = makeBigDecimalCalc('mul');
export const divBigDecimal = makeBigDecimalCalc('div');

export const handleNumber = (
  digit: number,
  n: StringNumber,
  handleFn: (a: StringNumberDecimal) => string,
) => {
  if (digit == 0) return handleFn(n);

  const unit = 10 ** Math.abs(digit);
  return digit > 0
    ? Decimal.mul(handleFn(Decimal.div(n, unit)), unit).toString()
    : Decimal.div(handleFn(Decimal.mul(n, unit)), unit).toString();
};

export function decimalRoundTo(digit: number): (n: StringNumber) => string;
export function decimalRoundTo(digit: number, n: StringNumber): string;
export function decimalRoundTo(digit: number, n?: StringNumber) {
  if (n == undefined) {
    return (_n: StringNumber) => decimalRoundTo(digit, _n);
  }

  return handleNumber(digit, n, (n) => Decimal.round(n).toString());
}

export function decimalFloorTo(digit: number): (n: StringNumber) => string;
export function decimalFloorTo(digit: number, n: StringNumber): string;
export function decimalFloorTo(digit: number, n?: StringNumber) {
  if (n == undefined) {
    return (_n: StringNumber) => decimalFloorTo(digit, _n);
  }

  return handleNumber(digit, n, (n) => Decimal.floor(n).toString());
}

export function decimalCeilTo(digit: number): (n: StringNumber) => string;
export function decimalCeilTo(digit: number, n: StringNumber): string;
export function decimalCeilTo(digit: number, n?: StringNumber) {
  if (n == undefined) {
    return (_n: StringNumber) => decimalCeilTo(digit, _n);
  }

  return handleNumber(digit, n, (n) => Decimal.ceil(n).toString());
}

export const greaterThan = (a: StringNumberDecimal, b: StringNumberDecimal) => new Decimal(a).gt(b);
export const greaterThanEqual = (a: StringNumberDecimal, b: StringNumberDecimal) => new Decimal(a).gte(b);
export const lessThan = (a: StringNumberDecimal, b: StringNumberDecimal) => new Decimal(a).lt(b);
export const lessThanEqual = (a: StringNumberDecimal, b: StringNumberDecimal) => new Decimal(a).lte(b);
