import { ProductCardData } from '../../../../../../features/ProductList/type';
import { html, View } from 'rune-ts';
import { makeSubScreenNavigate, PostMessageNavigate } from '../../../../../../shared/app/navigate';
import { dataStr } from '../../../../../../shared/util/dataStr';
import klass from './ProductCardName.module.scss';

type ProductCardNameData = Pick<ProductCardData, 'domain_name' | 'id' | 'name'>;

export class ProductCardName extends View<ProductCardNameData> {
  private readonly post_message: PostMessageNavigate;

  constructor(
    data: ProductCardNameData,
    private option: {
      klass?: string;
    } = {},
  ) {
    super(data, option);

    this.post_message = this.makePostMessage();
  }

  // FIXME: @kjj 폰케이스 이름 수정하는 로직 추가 -->
  override template() {
    const { name } = this.data;

    return html`
      <a
        href="${this.getProductDetailUrl()}"
        data-post-message="${dataStr(this.post_message)}"
        class="${this.option.klass} ${klass.product_name}"
      >
        ${html.preventEscape(name)}
      </a>
    `;
  }

  private getProductDetailUrl() {
    return `/${this.data.domain_name}/products/${this.data.id}`;
  }

  private makePostMessage(): PostMessageNavigate {
    return makeSubScreenNavigate(this.getProductDetailUrl());
  }
}
