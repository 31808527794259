import { isMarppleShopApp } from './service_app_name';

/**
 * 멤버십 기능 지원 여부를 확인하는 함수입니다. 멤버십 관련 기능 visible은 이 함수를 사용해주세요.
 *
 * - crew가 아프리카일 경우에도 막아야하는데, 이 함수로는 구분하게 만들 수 없어서 최소 조건만 넣음
 * - crew 작업하시는 분들은 알아서 잘 넣어줍시다. `is_afreeca` 참고
 */
export const isMembershipSupported = (): boolean => {
  return ET.lang === 'kr' && isMarppleShopApp();
};
