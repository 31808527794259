export class StoreError extends Error {
  data: { title: string; message: string; code: string };

  constructor(_data: { title: string; message: string }, public option?: { cause?: unknown }) {
    super(_data.title, option);
    this.data = {
      code: this.constructor.name,
      ..._data,
    };
    Error.captureStackTrace(this, this.constructor);
  }
}

export class InvalidStoreOwnerData extends StoreError {
  constructor(option?: { cause?: unknown }) {
    super(
      {
        title: '유저가 존재하지 않습니다.',
        message: '멤버십 스토어 소유주 변경 이벤트를 기록할 수 없습니다.',
      },
      option,
    );
  }
}
