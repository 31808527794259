import { z } from 'zod';
import { OnboardingSchemaS } from '../../../marppleShopStudioApp/features/Onboarding/outbound/share';
import { AuthorizationSchemaS } from '../../Authorization/outbound/share';
import { StoreSchemaS } from '../../Store/outbound/share';
import { EVENT_TYPE } from '../constant';

export const eventLogTypeEnum = z.nativeEnum(EVENT_TYPE);

export const eventLogScheme = z.discriminatedUnion('type', [
  OnboardingSchemaS.creatorStudioOnboardingEventLogDataScheme,
  StoreSchemaS.membershipStoreChangedEventLogDataScheme,
  AuthorizationSchemaS.userWithdrawalEventLogDataScheme,
]);
