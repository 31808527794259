import { html, View } from 'rune-ts';
import { ProductCard, ProductLikeToggleEvent } from '../ProductCard/ProductCard';
import { htmlIf } from '../../../../shared/util';
import klass from './ProductCardList.module.scss';
import {
  ProductCardData,
  ProductCardDefaultType,
  ProductCardListData,
  ProductCardOption,
  ProductCardType,
} from '../../../../features/ProductList/type';
import { DefaultProductListEmptyScreen } from './ProductCardListEmptyScreen';
import {
  compact,
  differenceBy,
  each,
  find,
  head,
  intersectionBy,
  map,
  pipe,
  pluck,
  some,
  toArray,
  uniqBy,
  zip,
} from '@fxts/core';

export type ProductCardListOption = ProductCardOption & {
  container_klass?: string;
  prevent_default_style?: boolean;
  limit?: number;
};

type EmptyViewOption = { empty_view?: View; empty_klass?: string };

export class ProductCardList<Type extends ProductCardType = ProductCardDefaultType> extends View<
  ProductCardListData<Type>
> {
  emptyListView: View;

  constructor(
    data: ProductCardListData<Type>,
    private option: ProductCardListOption,
    empty_option?: EmptyViewOption,
  ) {
    super(data, option);
    if (empty_option?.empty_view) {
      this.emptyListView = empty_option.empty_view;
    } else {
      this.emptyListView = new DefaultProductListEmptyScreen({ klass: empty_option?.empty_klass });
    }
  }

  override onRender() {
    this.delegate(ProductLikeToggleEvent, ProductCard, async (_, view) => {
      await view.toggleLikeButton();
    });
  }

  override template() {
    const { products } = this.data;
    const is_empty = !products.length;

    return html`
      <div
        class="${htmlIf(klass.product_list, !this.option.prevent_default_style)} ${this.option
          .container_klass ?? ''} ${htmlIf(klass.empty_list, is_empty)}"
      >
        ${is_empty
          ? this.emptyListView
          : products.map((product) => {
              return new ProductCard(product, this.option);
            })}
      </div>
    `;
  }

  public setProductsData(products: ProductCardData<Type>[]) {
    this.data.products = products;
    return this;
  }

  /**
   * 리스트의 앞 부분에 ProductCard 추가하는 메서드
   */
  public prependProductCards(products: ProductCardData<Type>[]) {
    const heads = products;
    const tails = differenceBy((p) => p.id, heads, this.data.products);

    // 변경이 없으면 종료
    const has_changed = pipe(
      heads,
      zip(this.data.products),
      some(([p1, p2]) => p1.id !== p2.id),
    );
    if (!has_changed) {
      return;
    }

    // 중복 상품 삭제
    const duplication_ids = pipe(
      intersectionBy((p) => p.id, heads, this.data.products),
      pluck('id'),
      toArray,
    );
    this.removeProductCardElementsByIds(duplication_ids);

    // 데이터 갱신
    this.setProductsData([...heads, ...tails]);

    const card_views = pipe(
      heads,
      uniqBy((p) => p.id),
      map((product) => {
        return new ProductCard(product, {
          ...this.option,
          is_lazy: false,
          is_hidden: true,
        });
      }),
      toArray,
    );

    const card_els = pipe(
      card_views,
      map((card_view) => card_view.render()),
      toArray,
    );
    this.element().prepend(...card_els);

    requestAnimationFrame(() => {
      card_views.forEach((view) => view.fadeIn());
    });
  }

  private findProductCardById(id: number): ProductCard | undefined {
    return find((p) => p.data.id === id, this.subViews(ProductCard));
  }

  private removeProductCardElementsByIds(ids: Iterable<number>): void {
    pipe(
      ids,
      map((id) => this.findProductCardById(id)),
      compact,
      each((view) => view.element().remove()),
    );
  }
}

export class RealTimeProductCardList extends ProductCardList<'REALTIME'> {
  public getLastOrderedAt(): Date | null {
    const head_product = head(this.data.products);
    return head_product ? head_product.ordered_at : null;
  }
}
