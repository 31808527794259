import { ProductCardData } from '../../../../features/ProductList/type';

type NonNullable<T> = T extends null ? never : T;

type RemoveNull<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};

type PeriodLimitedData = Pick<ProductCardData, 'sell_start_at' | 'sell_end_at'>;
type QuantityLimitedData = Pick<ProductCardData, 'quantity'>;
export type SoldOutData = PeriodLimitedData &
  QuantityLimitedData &
  Pick<ProductCardData, 'out_of_stock' | 'order_count'>;

export class ProductCardHelper {
  private constructor() {
    // 인스턴스 생성 방지
    throw new Error(`ProductCardHelper is static`);
  }

  static isLimited(data: QuantityLimitedData & PeriodLimitedData) {
    return ProductCardHelper.isQuantityLimited(data) || ProductCardHelper.isPeriodLimited(data);
  }

  /**
   * 수량한정 상품인지 확인하는 함수
   */
  static isQuantityLimited(data: QuantityLimitedData) {
    return data.quantity > 0;
  }

  /**
   * 기간한정 상품인지 확인하는 함수
   */
  static isPeriodLimited(data: PeriodLimitedData): data is RemoveNull<PeriodLimitedData> {
    return Boolean(data.sell_start_at && data.sell_end_at);
  }

  /**
   * 기간한정 상품이면서 판매 시작 전인지 확인하는 함수
   */
  static isUpcoming(data: PeriodLimitedData): data is RemoveNull<PeriodLimitedData> {
    return ProductCardHelper.isPeriodLimited(data) && new Date(data.sell_start_at) > new Date();
  }

  /**
   * 품절인지 확인하는 함수, 아래 조건 OR
   * - 1. 재고 수량 관리가 다 팔린 경우
   * - 2. "수량한정"일 때 잔여수량이 없는 경우
   * - 3. "기간한정"일 때 판매가 종료된 경우
   */
  static isSoldOut(data: SoldOutData): boolean {
    const now = new Date();

    return (
      /* 1. 재고 수량 관리가 다 팔린 경우 */
      data.out_of_stock ||
      /* 2. "수량한정"일 때 잔여수량이 없는 경우 */
      (data.quantity > 0 && data.order_count >= data.quantity) ||
      /* 3. "기간한정"일 때 판매가 종료된 경우 */
      (ProductCardHelper.isPeriodLimited(data) && data.sell_end_at < now) /* 기간한정 종료됨 */
    );
  }
}
